<template>
  <div id="myComponent">
    <v-row>
      <v-col md="6" offset-md="3" class="mt-4">
        <h1 class="ma-4 text-center" style="font-size: 28px">Clubhouseっぽい<br>画像メーカー</h1>
      </v-col>
    </v-row>
    <p class="text-center pt-2" style="font-size: 20px"><b><span style="color: #e34c51">「私、Clubhouse誘われてるよ！」</span></b>とマウントを取りたい人のためのサイト</p>
    <p class="text-center pb-8">あなたの入力情報が外部に保存されることはありません</p>
    <v-divider class="pb-6"></v-divider>
    <v-row>
      <v-col cols="11">
        <v-img
          lazy-src="https://picsum.photos/id/11/10/6"
          :aspect-ratio="1112/1007"
          width="400"
          src="/example.png"
          class="example ma-4"
        >
        </v-img>
      </v-col>
      <v-col cols="3" sm="2" >
        <v-img
          :src="upimage.fileUrl"
          aspect-ratio="1"
          width="100%"
          
        ></v-img>
      </v-col>
      <v-col cols="9" sm="6">
        <v-file-input
          accept="image/*"
          label="プロフィール画像を選択"
          @change="selectedFile"
          required
          :rules="nameRules"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-form v-model="valid">
      <v-row>
        <v-col cols="6" sm="4">
          <v-text-field
            v-model="sei"
            label="姓"
            :rules="nameRules"
            placeholder="Nisemono"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4">
          <v-text-field
            v-model="mei"
            label="名"
            :rules="nameRules"
            placeholder="Taro"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="id"
            label="ID"
            :rules="nameRules"
            placeholder="mount.toritai"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-btn @click="upload" color="primary" :disabled="isFilled">画像を作成する</v-btn>
    <p>{{message}}</p>
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="imgload"
      class="mb-5"
    ></v-progress-linear>
    <v-row v-if="eleShow">
      <v-col cols="12" sm="5" md="4">
        <img
          :src="image"
          class="mt-6 created"
          id="createdImage"
        >
      </v-col>
      <v-col cols="12" sm="7" md="5">
        <v-card class="mt-sm-7">
          <v-card-title>
            スマホ・タブレットの方
          </v-card-title>
          <v-card-text>
            <p>画像を長押しして保存</p>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            PCの方
          </v-card-title>
          <v-card-text>
            <v-btn @click="imageDownload" color="primary" elevation="2">ダウンロード</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      
    </v-row>
    <v-row>
      <v-col cols="12">
        <a href="https://px.a8.net/svt/ejp?a8mat=3H5G2S+AF34NU+3250+6J4I9" rel="nofollow">
          <img border="0" width="100%" alt="" src="https://www27.a8.net/svt/bgt?aid=210206116630&wid=002&eno=01&mid=s00000014274001097000&mc=1"></a>
          <img border="0" width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3H5G2S+AF34NU+3250+6J4I9" alt="">
      </v-col>
    </v-row> 
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400&display=swap');
@font-face {
  font-family: 'M PLUS Rounded 1c';
  src: url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c) format('ttf');
}

#myCanvas {
  border: 1px solid #000000;
}

#myComponent {
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

#html2canvas_img {
  background-image: url('~@/assets/template.png') no-repeat left bottom; 
  background-size: 200px auto;
  position: relative;
}

img {
  width: 100%;
  box-shadow: 0 10px 15px 0 rgba(30, 30, 30, .3);

}

.created {
  /* border: 10px solid blue; */
  padding: 9px;
  background: linear-gradient(140deg, rgba(227,76,81,0.8) 0%, rgba(86,119,171,0.8) 50%, rgba(38,174,95,0.8) 100%);
}

h1 {
  position: relative;
  color: #70664b;
  padding: 0.25em 1em;
  border-top: solid 2px black;
  border-bottom: solid 2px black;
}
h1:before, h1:after {
  content: '';
  position: absolute;
  top: -7px;
  width: 2px;
  height: -webkit-calc(100% + 14px);
  height: calc(100% + 14px);
  background-color: black;
}
h1:before {
  left: 7px;
}
h1:after {
  right: 7px;
}
.example {
  box-shadow: 0 10px 15px 0 rgba(30, 30, 30, .3);
}


</style>

<script>
import html2canvas from 'html2canvas'
import axios from 'axios'
import ImageUtil from "../lib/imageUtil";




export default {
  name: "ClubHouse",
  data() {
    return {
      canvas: null,
      context: null,
      isDrag: false,
      svgElement: null,
      files: null,
      avatar: '/default.png',
      message: '',
      error: '',
      image: '',
      valid: false,
      sei: '',
      mei: '',
      id: '',
      nameRules: [
        v => !!v || '入力してください',
      ],
      imgload: false,
      eleShow: false,
      upimage: { fileUrl: "/default.png", fileName: "", blob: null },
    };
  },
  mounted() {
    // this.canvas = document.querySelector("#myCanvas");
    // this.context = this.canvas.getContext("2d");
    // this.context.lineCap = "round";
    // this.context.lineJoin = "round";
    // this.context.lineWidth = 5;
    // this.context.strokeStyle = "#000000";
    // this.context.font = "48px 'M PLUS Rounded 1c'";
    // this.context.fillStyle = "rgb(255,200,61)";
    // this.context.fillRect(0, 0, 640, 640);
    // this.context.fillStyle = "rgb(44,44,44)";
    // this.context.fillText("🎉🥺 ぴえん", 10, 50);
  //   AWS.config.update({
  //   region: process.env.VUE_APP_BUCKET_REGION,
  //   credentials: new AWS.CognitoIdentityCredentials({
  //     IdentityPoolId: process.env.VUE_APP_POOL_ID
  //   })
  // })
  },
  methods: {
    async selectedFile(e) {
      // this.isUploading = true;
      const file = e;
      if (!file) {
        return;
      }
      try {
        // 圧縮した画像を取得
        const compFile = await ImageUtil.getCompressImageFileAsync(file);
        //ファイルサイズの表示
        // this.fileInfo.before.size = (file.size / 1024 / 1024).toFixed(4);
        // this.fileInfo.after.size = (compFile.size / 1024 / 1024).toFixed(4);
        // 画像情報の設定
        this.upimage.blob = compFile;
        this.upimage.fileUrl = await ImageUtil.getDataUrlFromFile(compFile);
        this.upimage.fileName = file.name;
      } catch (err) {
        // エラーメッセージ等を表示
      } finally {
        // this.isUploading = false;
      }
    },
    
    download() {
      let link = document.createElement("a");
      link.href = this.canvas.toDataURL("image/png");
      link.download = "canvas-" + new Date().getTime() + ".png";
      link.click();
    },
    chgImg() {
      var png = this.canvas.toDataURL("image/png");
      document.getElementById("newImg").src = png;
    },
    
    setError(error, text) {
      this.error = (error.response && error.response.data && error.response.data.error) || text
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    onImageChange(e) {
      // const images = e;
      // console.log(images)
      // this.getBase64(images[0])
      //   .then(image => this.avatar = image)
      //   .catch(error => this.setError(error, 'Failed to upload'))
      const fr = new FileReader()
      fr.readAsDataURL(e)
      fr.addEventListener('load', () => {
        this.avatar = fr.result
      })
    },
    upload() {
      if(this.avatar) {
        this.message = 'Uploaded'
        this.imgload = true
        this.error = ''
        let self = this
        // const encodedData =this.avatar
        const encodedData = this.upimage.fileUrl
        const fileData = encodedData.replace(/^data:\w+\/\w+;base64,/, '')
        axios.post('https://g2ss9xtgic.execute-api.ap-northeast-1.amazonaws.com/default/image_process', {
          dispName: this.mei + ' ' + this.sei,
          userId: '@' + this.id,
          image: fileData
        }, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(function(response) {
          self.imgload = false
          self.eleShow = true
          self.image = 'data:image/png;base64,' + response.data.result
          // imgtmp = response.data.result
          // this.result = 'data:image/png;base64' + response.data
          // this.image = 'data:image/png;base64,' + response.data.result
        })
        // .then(function(){
        //   this.image = imgtmp
        // })
        // .then(function(response) {
        //   this.result = response.data.result
        // })
        .catch(function (error) {
          console.log(error);
        })
      } else {
        this.error = "No Image"
      }
    },
    // onFileChange(e) {
    //   // var s3 = new AWS.S3({
    //   //   apiVersion: "2006-03-01",
    //   //   params: { Bucket: process.env.VUE_APP_BUCKET_NAME }
    //   // });
    //   this.files = e

    //   // if(!this.files.length) {
    //   //   return alert("ファイルを選択してください");
    //   // }
    //   var file = this.files;
    //   var fileName = file.name;

      // var key = Math.random().toString(32).substring(2) + fileName;

      // AWS.config.region = process.env.VUE_APP_BUCKET_REGION;
      // AWS.config.credentials=new AWS.CognitoIdentityCredentials({IdentityPoolId: process.env.VUE_APP_POOL_ID});
      // AWS.config.credentials.get(function(err){
      //   if(!err) {
      //     console.log("Cognito");
      //   }
      // });
      // var s3_client = new AWS.S3({params:{Bucket: process.env.VUE_APP_BUCKET_NAME}});
      
      // s3_client.putObject({Key: key, ContentType: file.type, Body: file, ACL: "public-read"},
      // function(err, data){
      //   if(data!==null){
      //     alert("success");
      //   }else{
      //     alert("failed");
      //   }
      // });
      // var upload = new AWS.S3.ManagedUpload({
      //   params: {
      //     Bucket: process.env.VUE_APP_BUCKET_NAME,
      //     Key: key,
      //     Body: file
      //   }
      // });

      // var promise = upload.promise();

      // promise.then(
      //   function() {
      //     alert("Successfully uploaded photo.");
      //   },
      //   function(err) {
      //     return alert("There was an error uploading your photo: ", err.message);
      //   }
      // );
    // },
    generate_img() {
      html2canvas(document.querySelector('#html2canvas_img')).then(canvas => {
        // var ctx = canvas.getContext('2d');
        // var img = new Image();
        // img.src = 'image.png';
        // document.body.appendChild(img)
        // ctx.drawImage(img, 0, 0);
        var downloadEle = document.createElement('a');
        downloadEle.href = canvas.toDataURL('image/png');
        var png = canvas.toDataURL('image/png');
        document.getElementById("convertedImg").src = png;
        downloadEle.download = 'canvas.png';
        downloadEle.click();
      });
    },
    imageDownload() {
      var downloadEle = document.createElement('a');
      downloadEle.href = this.image;
      downloadEle.download = 'clubhouse.png';
      downloadEle.click();
    }
  },
  computed: {
    isFilled () {
      if(this.sei.length > 0 && this.mei.length > 0 && this.id.length > 0 && this.avatar.length > 0) {
        return false
      } else {
        return true
      }
    }
  }
};
</script>
