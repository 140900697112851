<template>
    <div>
        <h1>免責事項</h1>
        <p>当サイトとClubhouseの運営会社Alpha Exploration Co.は一切関係がございません。
            また、当サイトが提供するサービスは正確性を一切保証しておりません。
            当サイトの利用を通じて生じた損害等の一切の責任を負いかねますのでご了承ください。
        </p>
        

    </div>
  
</template>

<script>
export default {
    name: 'Kiyaku'

}
</script>

<style>

</style>