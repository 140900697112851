<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <club-house/>
  </div>
</template>

<script>
import ClubHouse from '../components/ClubHouse.vue'
// @ is an alias to /src
// import DrawTool from '../components/DrawTool.vue'

export default {
  name: 'Home',
  components: {
    // DrawTool,
    ClubHouse
  }
}
</script>
