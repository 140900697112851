<template>
  <v-app>
    <v-app-bar app color="title" dark>
      
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="/logo.png"
          transition="scale-transition"
          width="40"

        />
      </div>

      <!-- <v-spacer></v-spacer> -->

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
      <!-- <span class="mr-2">IMAGE works</span> -->
      <router-link to="/">
      <v-img
        alt="Vuetify Logo"
        class="shrink"
        contain
        src="/title.svg"
        transition="scale-transition"
        width="200"
      />
      </router-link>
    </v-app-bar>

    <v-main>
      <div class="ground">
        <v-container>
          <router-view />
        </v-container>
        <v-footer color="title lighten-1" padless>
          <v-row justify="center" no-gutters>
            <v-btn
              v-for="(value, name) in links"
              :key="value"
              color="white"
              text
              rounded
              class="my-2"
              @click="movelink(name)"
            >
              {{ value }}
            </v-btn>
            <v-col
              class="title lighten-2 py-4 text-center white--text"
              cols="12"
            >
              {{ new Date().getFullYear() }} — <strong>image works</strong>
            </v-col>
          </v-row>
        </v-footer>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    links: {
        kiyaku: '利用規約',
        privacy: 'プライバシーポリシー'
      },
  }),
  methods: {
    movelink(link) {
      this.$router.push(link)
    }
  }
};
</script>

<style lang="scss">
.ground {
  background-color: #f3f0e5;
}
</style>
