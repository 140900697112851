<template>
    <div>
        <h1>プライバシーポリシー</h1>

        <h2>個人情報について</h2>

        <p>本サイトの利用にあたって入力された画像、氏名、ID等の情報は一切保存しておりません。</p>

        <!-- <h2>広告について</h2>

        <p>当サイトでは、第三者配信の広告サービス（Googleアドセンス、A8.net）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。
        クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、お客様個人を特定できるものではありません。

Cookieを無効にする方法やGoogleアドセンスに関する詳細は「広告 – ポリシーと規約 – Google」をご確認ください。

また、●●は、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。 -->

        <h2>アクセス解析ツールについて</h2>

        <p>当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。</p>

    </div>
  
</template>

<script>
export default {
    name: 'Privacy'
}
</script>

<style>

</style>